.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */
.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  min-height: 100vh;
}
.navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

#findbar {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.ButtonLists {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5px;
  width: 100%;
}
.ButtonLists button {
  width: 350px;
  margin-top: 5px;
}
/* .InventoryLists {
  width: 95%;
  margin-top: 20px;
} */
/* .div_shk_opshion {
  
} */

#InpClickQantityToSend {
  width: 50px;
  text-align: center;
}
#InpShkid {
  max-width: 125px;
}
.App-link {
  color: #61dafb;
}

/* #InventaryTable {
  min-width: 345px;
} */
#InventaryTable td {
  padding: 0px;
  color: inherit;
}

.TD_Shk {
  max-width: 125px;
}
.TD_Name {
}
.TD_Num {
  width: 50px;
}

main {
  padding: 5px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
